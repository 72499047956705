.Holos-alert {
  font-family: GraphikRegular;
  .popover-content {
    width: 25%;
    padding: 1em 2em;
    .popover-viewport {
      .modal-title {
        padding: 0 0 1.25em;
        overflow: hidden;
        .holos-alert-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: var(--softBlack);
          .Icon-text {
            width: 100%;
            justify-content: flex-start;
            align-items: center;
            > div {
              width: 100%;
            }
            svg {
              color: var(--purpleBHP);
              margin-right: 8px;
            }
            .text {
              width: 75%;
              line-height: 0;
            }
          }
        }

        .holos-alert-actions {
          display: flex;
          align-items: center;
          svg {
            color: var(--black);
            opacity: 0.54;
            cursor: pointer;
          }
          > * {
            margin-left: 1em;
          }
        }
      }
      .modal-content {
        font-family: GraphikRegular;
        overflow: hidden;
        border-top: 1px solid #e0e0e0;
        font-size: 14px;
        .holos-alert-content {
          overflow: hidden;
          color: var(--softBlack);
          padding: 2em 0;
          text-align: left;
        }
      }
      grid-template-rows: auto;
    }
    .modal-footer {
      .Holos-button {
        min-width: 14.625em;
        width: 100%;
        button {
          display: flex;
          justify-content: center;
          font-weight: 600;
          div {
            justify-content: center;
          }
        }
      }
      padding: 0;
    }
    .modal-footer::before {
      background-image: none;
    }
  }
  .HolosInput {
    height: 34px;
  }
  .double-cta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    .Holos-link {
      margin-top: 16px;
    }
  }

  .double-cta-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .Holos-link {
      width: 90%;
      margin-top: 16px;
    }
  }
  .css-2b097c-container {
    margin-top: 10px;
    .css-yk16xz-control {
      background-color: transparent;
      border: 1px solid #e0e0e0 !important;
      border-radius: 8px;
    }
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
}
