.NewPlaque {
  p {
    color: var(--softBlack);
    font-size: 14px;
    font-weight: 400;
  }

  .titleContainer {
    margin: 20px 0;
    color: #444444;
  }
  .inputsContainer {
    display: flex;
    flex-direction: column;
    .first-select,
    .second-select {
      display: grid;
      grid-template-columns: 1fr 1fr;
      > div {
        margin-left: 0 1em;
      }
    }

    .first-select {
      .circle-input {
        margin-right: 0.3125em;
        margin-bottom: 0.5em;
      }
    }

    .second-select {
      .recognition-input {
        margin-right: 0.3125em;
      }
    }

    .without-recognitions {
      padding: 1em;
      color: var(--error);
      background-color: var(--softWhite);
      border-radius: 0.5em;
    }
    .HolosInput,
    .IconSelect {
      padding: 0.35em 0.5em 0.35em 0.813em;
    }
  }
  .PlaqueLocation {
    background-color: var(--softWhite);
    border-radius: 0.5em;
    margin-top: 0.625em;
    padding: 0.75em 0.938em 0.75em 0.938em;
    height: 100%;
    .title {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
    }
    .body {
      margin: 1.688em 0 0 0;
      width: 100%;
      display: flex;
      flex-direction: column;

      .row {
        display: flex;
      }

      .containerMap {
        .img {
          width: 100%;
          border-radius: 0.5em;
          background-position: center;
          border: transparent;
          cursor: pointer;
        }
        .img.empty {
          filter: grayscale(100%);
        }

        .mapSelector.invalid {
          .css-1wa3eu0-placeholder {
            color: var(--error);
          }
        }

        .mapSelector {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin: 0 0 0 0.625em;

          a {
            text-decoration: none;
          }
          p {
            margin: 1.563em 0 1.813em 1.375em;
          }
          .Holos-button {
            align-self: flex-end;
            button {
              line-height: 19.8px;
              padding: 0.75em 1.25em 0.75em 1.25em;
            }
          }
        }
      }

      .containerCoord {
        margin-top: 2.188em;
        .coord {
          display: flex;
          flex-direction: row;
          width: 25%;
          align-items: baseline;
          p {
            margin: 0 0.5em 0 2.5em;
          }
        }
        @media (max-width: 1366px) {
          .coord {
            width: 43%;
          }
          p {
            margin: 0 0.5em 0 1em;
          }
        }
      }

      .containerReference {
        margin-top: 2.75em;
        > .Icon-text {
          width: 12%;
        }
        .css-2b097c-container {
          padding: 0 0 0 1.2em;
          width: 27%;
        }
        .toogle {
          padding: 0 0 0 1.2em;
          display: flex;
          width: 35%;
        }
        .inMachine {
          padding: 0 0 0 1.2em;
          font-size: 0.875em;
          font-weight: 400;
          color: #444444;
          display: flex;
          width: 25%;
          align-items: center;
          .tag {
            width: 7.5em;
            margin-left: 1em;
            font-size: 0.875em;
          }
          @media (max-width: 1280px) {
            .tag {
              width: 50%;
            }
          }
        }
      }

      .containerPhotos {
        display: flex;
        flex-direction: column;
        margin-top: 1.25em;
        //mock upload photo jpg
        .DragAndDrop {
          height: 100%;
        }
        .Icon-text {
          margin: 0.813em 0 0 0;
          color: var(--softBlack);
        }
      }
    }
  }

  .pdfContainer {
    background-color: var(--softWhite);
    border-radius: 0.5em;
    margin: 0.625em 0 0 0;
    .DragAndDrop {
      .columnContainer {
        padding: 1.031em 0 1.438em 0.688em;
      }
    }
  }

  .saveContainer {
    display: flex;
    flex-direction: row-reverse;
    .Holos-button {
      margin: 1.25em 0 0 0;
      button {
        padding: 0.938em 1.25em 0.938em 1.25em;
      }
    }
  }
}
