.Icon-text {
  display: flex;
  justify-content: flex-start;
  color: var(--softBlack);
  svg {
    opacity: 0.54;
    font-size: 24px;
  }
  .icon {
    background-color: var(--softBlack);
    height: 24px;
    width: 24px;
  }
  &.purple-icon {
    svg {
      opacity: 1;
      color: var(--purpleBHP);
    }
    .icon {
      background-color: var(--purpleBHP);
      opacity: 1;
    }
  }

  &.purple-icon-text {
    color: var(--purpleBHP);
    svg {
      opacity: 1;
      color: var(--purpleBHP);
    }
    .icon {
      background-color: var(--purpleBHP);
      opacity: 1;
    }
  }

  &.white-icon-text {
    color: white;
    svg {
      opacity: 1;
      color: white;
    }
    .icon {
      background-color: white;
      opacity: 1;
    }
  }

  &.red-icon-text {
    color: var(--error);
    svg {
      opacity: 1;
      color: var(--error);
      font-size: 16px;
    }
    .icon {
      color: var(--error);
      opacity: 1;
    }
  }

  &.softBlack-icon-text {
    color: var(--softBlack);
    svg {
      opacity: 1;
      color: var(--softBlack);
    }
    .icon {
      color: var(--softBlack);
      opacity: 1;
    }
  }
}
