.Support {
  font-style: normal;
  line-height: 15.4px;
  height: 100%;

  .popover-content {
    border: 1px solid #e0e0e0;
    border-radius: 16px;
    width: 350px;
    height: 240px;
  }
  .popover-viewport {
    padding: 16px;
    display: grid;
    grid-template-areas:
      "title"
      "content"
      "footer";
    grid-template-rows: 1fr 8fr 1fr;
    overflow: hidden;
  }

  .support-title {
    grid-area: title;
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0 0 16px 0;
    > .support-row {
      display: flex;
      justify-content: space-evenly;
    }
    .img {
      width: 32px;
      height: 32px;
      margin: 0 8px 0 0;
      background-size: auto;
      background-position: center;
    }
    label {
      font-size: 18px;
      color: var(--purpleBHP);
      font-weight: 600;
      align-self: center;
    }
    .Close {
      opacity: 0.54;
      align-self: center;
    }
  }

  .support-content {
    grid-area: content;
    overflow: hidden;
    padding: 0;
    > .support-row {
      display: flex;
      justify-content: flex-start;
      padding: 29.008px 0 0 52px;
      .span-title {
        font-weight: 600;
      }
    }
  }

  span {
    font-size: 14px;
    font-weight: 400;
    color: var(--softBlack);
    -moz-user-select: text; /* Firefox */
    -ms-user-select: text; /* Internet Explorer */
    -khtml-user-select: text; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: text; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: text; /* Disable Android and iOS callouts*/
  }
}

@supports (-webkit-touch-callout: none) {
  .popover-content {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
}
