.Profile {
  margin: 1.125em 0 1.938em 0;
  padding: 0.5em 0 0.5em 1.25em;
  display: flex;
  justify-content: flex-start;
  width: min-content;
  .text {
    font-size: 14px;
    display: inherit;
    flex-direction: column;
    align-self: center;
    padding-left: 0.652em;
    color: #444444;
    flex-wrap: wrap;
    max-width: 70%;
  }
  .imgProfile {
    width: 2.8em;
    height: 2.8em;
    border: 1px solid var(--purpleBHP);
    border-radius: 2em;
    background-size: cover;
    background-position: center;
  }
}
