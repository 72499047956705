.Preview-data {
  background: var(--softWhite);
  border-radius: 1em 1em 0px 0px;
  height: 10em;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: max-content;
  .preview-data-border {
    .Maximize {
      opacity: 0.34;
      text-align: center;
      font-size: 1.6em;
    }
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
  .preview-data-title {
    padding: 0 0 1.02em 1.25em;
    .Icon-text {
      .text {
        width: -webkit-fill-available;
        margin-right: 35px;
      }
    }
  }
  .Info-card {
    .row {
      padding: 0.688em 0 0.938em 1.25em;
      .Icon-text {
        max-width: 40%;
        min-width: 30%;
      }
      span {
        margin: 0 0 0 0.5em;
        max-width: 60%;
        min-width: 50%;
        font-size: 14px;
        font-weight: 400;
        color: var(--softBlack);
      }
    }
  }
  .preview-data-qr {
    display: flex;
    border-top: 1px solid #e0e0e0;
    .Icon-text {
      max-width: 40%;
      min-width: 30%;
      padding: 0.688em 0 0.938em 1.25em;
    }
    .img-qr {
      margin: 0 0 0 1.3em;
      max-width: 60%;
      min-width: 50%;
      img {
        width: 20%;
      }
    }
  }
  .preview-data-img {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: space-between;
    max-height: 225px;
    img {
      max-height: 225px;
    }
  }
}
