.MapModal {
  .popover-content {
    width: 80%;
    height: 80%;

    .map-modal-gmaps {
      min-height: 18em;
    }

    .map-modal-title {
      font-family: GraphikRegular;
      font-size: 15px;
      font-weight: 400;
      display: flex;
      justify-content: space-between;
      grid-area: title;
    }

    .map-modal-list {
      overflow: hidden;
      .headers {
        padding-left: 2em;
        position: fixed;
        background-color: white;
        width: 100%;
        z-index: 1;
      }

      .list-body {
        // height: 10em;
        margin-top: 4.5em;
        overflow: auto;
        .map-modal-item {
          text-align: left;
          margin-left: 2em;
        }
      }
    }
  }
}

@media (max-height: 680px) {
  .map-modal-list {
    .list-body {
      max-height: 8vh;
    }
  }
}

@media (min-height: 700px) {
  .map-modal-list {
    .list-body {
      max-height: 20vh;
    }
  }
}

@media (min-height: 900px) {
  .map-modal-list {
    .list-body {
      max-height: 30vh;
    }
  }
}
