.DragAndDrop {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  .columnContainer {
    padding: 0.813em 0.813em 1.438em 0.813em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: solid var(--lightGrey) 1px;
    border-radius: 0.5em;
    width: 100%;
    height: 11.75em;
    align-items: center;
    .uploadedContainer {
      display: flex;
      margin-bottom: 0.3em;
      .fileUploaded {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 0.5em 0em 0.5em;
        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 12em;
        }
      }
    }
    .fileContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      span {
        align-self: center;
      }
      .upload {
        display: flex;
        justify-content: center;
        .inputFile {
          width: 50%;
          margin-left: 0.3em;
        }
        .inputFile::-webkit-file-upload-button {
          visibility: hidden;
        }
        .inputFile:focus {
          outline: none;
        }
        .inputFile::before {
          content: "seleccionar manualmente";
          display: inline-block;
          background: transparent;
          outline: none;
          -webkit-user-select: none;
          cursor: pointer;
          color: var(--purpleBHP);
          font-weight: 400;
          font-size: 14px;
        }
        .inputFile:hover::before {
          outline: none;
          border: none;
        }
        .inputFile:active::before {
          background: none;
        }
      }
    }
    .Icon-text {
      align-self: start;
    }
  }
  span {
    font-size: 14px;
    font-weight: 400;
    color: var(--softBlack);
  }
  .columnContainer.drag {
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid var(--purpleBHP) 3px;
    .MuiSvgIcon-root {
      width: 5em;
    }
  }

  .icon {
    color: black;
    opacity: 0.54;
  }

  .InsertDriveFile {
    width: 1.8em;
    height: 1.8em;
  }

  .Collections {
    margin-left: 0.538em;
  }

  .AttachFileOutlined {
    transform: rotate(90deg);
  }

  .HighlightOffOutlined {
    position: relative;
    top: -80%;
    left: 18%;
  }

  .invalid-file {
    color: var(--error);
    align-self: flex-start;
  }
}
