.SideBar {
  padding: 2.063em 1.25em 0 1.25em;
  .module-bar {
    padding: 0.625em 0 1.25em 0;
  }
}
.background {
  background-color: #fcfcfc;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  padding-bottom: 10px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
}

.hide {
  visibility: hidden;
}

.mobile-browser {
  display: flex;
  justify-content: space-between;
  margin: 0 0 1.75em 0;
  position: sticky;
  top: 0;
  z-index: 2;
}

.open-menu {
  background-color: #fcfcfc;
}
