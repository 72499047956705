.Dashboard {
  display: grid;
  grid-template-columns: 300px 1fr;
  background-color: var(--grey);
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  .dashboard-sidebar {
    width: 300px;
    padding: 2.063em 1.25em 0 1.25em;
    background-color: #fcfcfc;
  }
}
.dashboard-body {
  padding: 1.875em;
  .dashboard-content {
    .dashboard-title {
      font-size: 18px;
      color: #444444;
      margin-top: 2rem;
    }
    .dashboard-module-cards {
      display: flex;
      flex-wrap: wrap;
      grid-gap: 1rem;
      justify-content: space-between;
      margin: 1rem 0 2rem 0;
      .dashboard-card {
        column-span: 1;
        border-radius: 16px;
        height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #fcfcfc;
        &:hover {
          border: 5px solid var(--purpleBHP);
        }
        .dashboard-card-icon {
          color: black;
          opacity: 0.54;
          width: 84px;
          height: 84px;

          &.ideas-icon {
            transform: rotate(180deg);
          }
        }
      }
      .dashboard-card-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2rem 2rem 0 2rem;
        .dashboard-card-title {
          margin-bottom: 10px;
          color: var(--softBlack);
        }
        .dashboard-card-slogan {
          color: var(--softBlack);
        }
      }
    }
    .profile-status {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .profile-status-buttons {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px;
        z-index: 0;
        :before {
          z-index: -1;
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          border-top: 1px solid var(--purpleBHP);
          background: var(--purpleBHP);
          width: 100%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
