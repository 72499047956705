.Menu-browser-mobile {
  .menu-navigation {
    opacity: 0.54;
    color: #444444;
    vertical-align: middle;
  }
  .menu-items {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    width: 100%;
    background: var(--softWhite);
    padding: 0.625em 0.625em 1.25em 0.625em;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    z-index: 2;
    .Button-tab {
      .Holos-button {
        button {
          padding: 0em 1.25em 0em 1.25em;
        }
      }
      .button0 {
        border-top: 1px solid #e0e0e0;
        padding: 1.25em 0 0 0;
      }
    }
    .Icon-text {
      svg {
        font-size: 1.2em;
      }
    }
  }
}
