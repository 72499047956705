.Info-card {
  .row {
    display: flex;
    flex-direction: row;
    border-top: solid #e0e0e0 1px;

    .aws-path {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    span {
      width: fit-content;
      align-self: center;
      padding-left: 0.5em;
    }
    .aws-path {
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
