.plaqueHome {
  display: grid;
  grid-template-columns: auto 2fr;
  height: 100%;

  .mainContent {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 1.875em;
    background-color: var(--grey);
  }
}
