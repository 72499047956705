.Preview-plaque {
  .BaseBody {
    .body {
      padding: 1.25em;
      .columnContainer {
        display: flex;
        flex-direction: row;
        height: 100%;
        .viewer {
          width: 30%;
          embed {
            height: 100%;
          }
        }
        .data {
          width: 70%;
          display: flex;
          flex-direction: column;
          padding: 0 0 0 3.5em;
          .headPreview {
            display: flex;
            justify-content: space-between;
            margin: 0 0 1.25em 0;
            .titlePreview {
              display: flex;
              .iconQR {
                height: 3em;
                width: 3em;
                background-color: black;
                margin: 0 1.7em 0 0;
              }
            }
            .editPreview {
              display: flex;
              align-self: center;
              .BorderColorOutlined {
                margin: 0 0.8em 0;
                opacity: 0.54;
              }
              .NotInterested {
                opacity: 0.54;
                cursor: pointer;
              }
            }
            .disabledPlaque {
              padding: 0.6em 1em 1em 1em;
              background-color: #d92534;
              height: 2.1875em;
              margin-left: 1em;
              border-radius: 0.5em;
              color: var(--softWhite);
              font-size: 14px;
              font-weight: 600;
              line-height: 0.9625em;
              margin-top: 0.5em;
            }
          }
          .bodyPreview {
            .start {
              padding: 0.688em 0 0.938em 1em;
              .Icon-text {
                .text {
                  width: -webkit-fill-available;
                  margin-right: 30px;
                }
              }
            }
            //Info-card
            .row {
              padding: 0.688em 0 0.938em 1em;
              display: flex;
              flex-direction: row;
              > .Icon-text {
                max-width: 50%;
                min-width: 40%;
              }
              span {
                max-width: 50%;
                min-width: 40%;
                font-size: 14px;
                font-weight: 400;
                color: var(--softBlack);
              }
            }
            .end {
              justify-content: space-between;
              padding: 1.188em 0 0 1.188em;
              .Icon-text {
                align-self: flex-start;
              }
              .img {
                width: 5em;
                height: 5em;
                background-size: cover;
                background-position: center;
                align-self: flex-end;
              }
            }
          }
        }
      }
    }
  }
}
