.Holos-button {
  button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 0.5em;
    padding: 0.625em 1.25em 0.625em 1.25em;
    width: 100%;
    .Icon-text {
      width: 100%;
    }
    img {
      margin-right: 0.5em;
    }
  }
  button:focus {
    outline: none;
  }
  button:disabled {
    background-color: rgb(224, 224, 224);
  }
}

.purple-background {
  button {
    background: var(--purpleBHP);
  }
}
.gray-background {
  button {
    background: var(--lightGrey);
  }
}

.purple-rounded-corners {
  button {
    border-radius: 32px;
    background: var(--purpleBHP);
  }
}
.transparent-rounded-corners {
  button {
    border-radius: 32px;
    border: 1px solid #e0e0e0;
    background: var(--softWhite);
  }
  :hover {
    background-color: #e0e0e0;
  }
}

.transparent-background {
  button {
    background: transparent;
  }
  &.select {
    button {
      .Icon-text {
        svg {
          opacity: 1;
        }
        .icon {
          opacity: 1;
        }
      }
    }
    &.purple-background-select {
      button {
        background: var(--purpleBHP);
        .Icon-text {
          color: white;
          svg {
            color: white;
            path {
              stroke: white;
            }
          }
          .icon {
            background-color: white;
          }
        }
      }
    }
    &.purple-select {
      button {
        .Icon-text {
          color: var(--purpleBHP);
          svg {
            opacity: 1;
            color: var(--purpleBHP);
          }
          .icon {
            background-color: var(--purpleBHP);
            opacity: 1;
          }
        }
      }
    }
    &.grey-background-select {
      button {
        background: rgb(224, 224, 224);
        .Icon-text {
          color: var(--softBlack);
          svg {
            color: var(--softBlack);
          }
          .icon {
            background-color: var(--softBlack);
          }
        }
      }
    }
  }
}

.white-hover {
  .Icon-text:hover {
    color: white;
    svg {
      opacity: 1;
      color: white;
    }
    .icon {
      background-color: white;
      opacity: 1;
    }
  }
}

.purple-hover {
  .Icon-text:hover {
    color: var(--purpleBHP);
    svg {
      opacity: 1;
      color: var(--purpleBHP);
    }
    .icon {
      background-color: var(--purpleBHP);
      opacity: 1;
    }
  }
}
.white-background-hover {
  button:hover {
    background-color: transparent;

    .Icon-text {
      color: var(--purpleBHP);
      svg {
        opacity: 1;
        color: var(--purpleBHP);
      }
      .icon {
        background-color: var(--purpleBHP);
        opacity: 1;
      }
    }
  }
}
.grey-background-hover {
  button:hover {
    background: rgb(224, 224, 224);

    .Icon-text {
      color: var(--softBlack);
      svg {
        opacity: 1;
        color: var(--softBlack);
      }
      .icon {
        background-color: var(--softBlack);
        opacity: 1;
      }
    }
  }
}
