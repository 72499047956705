.Preview-location {
  background: var(--softWhite);
  border-radius: 1em 1em 0px 0px;
  height: 10em;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: max-content;
}
.preview-location-border {
    .Maximize {
      opacity: 0.34;
      text-align: center;
      font-size: 1.6em;
    }
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
  .preview-location-title {
    padding: 0 0 1.02em 1.25em;
  }
  .Info-card {
    .row {
      padding: 0.688em 0 0.938em 1.25em;
      .Icon-text {
        max-width: 40%;
        min-width: 30%;
      }
      span {
        margin: 0 0 0 0.5em;
        max-width: 60%;
        min-width: 50%;
        font-size: 14px;
        font-weight: 400;
        color: var(--softBlack);
      }
    }
  }