.BaseBody {
  // height: 100%;
  width: 100%;
  .bodyHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1.25em 0 1.25em 0;
  }
  .body {
    background-color: white;
    height: 100%;
    width: 100%;
    background: var(--softWhite);
    border-radius: 1em;
  }
}
