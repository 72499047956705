.Preview-view-finder {
  padding: 0 0.625em 0.625em 0.625em;
  .preview-tab {
    background: var(--softWhite);
    padding: 0.625em 0.625em 0.625em 0.75em;
    border-radius: 0.5em;
    .tab {
      display: flex;
      justify-content: space-between;
      padding: 1.25em 0 0 0;
      .Share {
        opacity: 0.54;
        color: #444444;
      }
    }
  }
  .preview-pdf {
    background: var(--softWhite);
    border-radius: 0.5em;
    height: 100%;
    margin: 0.625em 0 0 0;
    text-align: center;
  }
  .Preview-location,
  .Preview-data {
    transition: transform 0.4s ease-out;
  }
}
