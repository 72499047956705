.HolosInput {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--lightGrey);
  svg {
    opacity: 0.54;
    color: var(--black);
  }
  input {
    padding: 5px 10px 5px 10px;
    border-radius: 8px;
    background-color: transparent;
    border: none;
    width: 100%;
    color: var(--softBlack);
    font-size: 14px;
    font-weight: 400;
    img {
      margin-right: 8px;
    }
  }
  input:focus {
    outline: none;
  }
  input::placeholder {
    color: var(--mediumGrey);
  }
  &.reverse {
    flex-direction: row-reverse;
  }
}

.HolosInput.disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.HolosInput.invalid {
  input::placeholder {
    color: var(--error);
  }
}
.HolosInput.hidden {
  visibility: hidden;
  display: none;
}

.input-error {
  .Icon-text {
    width: max-content;
    font-size: 12px;

    .text {
      margin-top: 3px;
    }
  }
}
