/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** holos **/
  --purpleBHP: #742054;
  --softWhite: #fcfcfc;
  --lightGrey: #dadada;
  --mediumGrey: #999999;
  --grey: #c4c4c4;
  --black: #000;
  --softBlack: #444444;
  --error: #d93025;

  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

//Select-react
.css-2b097c-container {
  font-size: 14px;
  color: var(--softBlack);
  .css-yk16xz-control {
    height: 39px;
    cursor: pointer;
    .css-1wa3eu0-placeholder {
      color: var(--mediumGrey);
    }
  }
  // separator
  .css-1okebmr-indicatorSeparator {
    background: none;
  }
  //icon expand
  .css-6q0nyr-Svg {
    fill: black;
    opacity: 0.54;
  }
}

/** dark-theme **/
@media (prefers-color-scheme: dark) {
  .SideBar {
    background-color: #fcfcfc;
  }
  .mobile-browser {
    background-color: var(--grey);
  }
  .holos-search {
    color: #444444;
  }
}

/** YoContribuyo Font **/

@font-face {
  font-family: GraphikRegular;
  src: url("../assets/fonts/GraphikRegular.ttf") format("truetype");
}

html,
body,
#root {
  height: 100%;
}

* {
  font-family: GraphikRegular;
  font-style: normal;
  line-height: 15.4px;
}

/** General Styles **/

.opacity-54 {
  opacity: 0.54;
}

.loading {
  --background: transparent;
  --spinner-color: var(--purpleBHP);
  .loading-wrapper.sc-ion-loading-md {
    box-shadow: none;
  }
}

.pl-small {
  padding-left: 8px;
}

.pl-medium {
  padding-left: 16px;
}

.pl-large {
  padding-left: 24px;
}

.mb-small {
  margin-bottom: 8px;
}

.mb-medium {
  margin-bottom: 16px;
}

.mb-large {
  margin-bottom: 24px;
}

.mt-small {
  margin-top: 8px;
}

.mt-medium {
  margin-top: 16px;
}

.mt-large {
  margin-top: 24px;
}

.mr-large {
  margin-right: 24px;
}

.mr-small {
  margin-right: 8px;
}

.mr-medium {
  margin-right: 16px;
}

.ml-large {
  margin-left: 24px;
}

.ml-small {
  margin-left: 8px;
}

.ml-medium {
  margin-left: 16px;
}

.lessBold {
  font-weight: 500;
}

.bold {
  font-weight: 600;
}

.small {
  font-size: 14px;
}

.smallest {
  font-size: 14px;
}

.medium {
  font-size: 16px;
}

.great {
  font-size: 18px;
}

.large {
  font-size: 24px;
}

.required::after {
  content: "*";
  margin-left: 0.25rem;
}

.large-icon {
  svg {
    font-size: 48px;
  }
  div.icon {
    height: 48px;
    width: 48px;
  }
}

.link {
  color: var(--softBlack);
  text-decoration: underline;
}

.causes-icon {
  transform: rotate(45deg);
}

.floating-modal-container {
  position: relative;
  cursor: pointer;
}

.white-round-number {
  background-color: var(--softWhite);
  border-radius: 50%;
  padding: 8px;
  width: 40px;
  height: 40px;
  font-size: 18px;
  line-height: 1.3;
  text-align: center;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  &.plus-button {
    padding-left: 12px;
  }
}

.purple-round-number {
  background-color: var(--purpleBHP);
  color: var(--softWhite);
  border-radius: 50%;
  padding: 8px;
  width: 40px;
  height: 40px;
  font-size: 18px;
  line-height: 1.3;
  text-align: center;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
}

.causes-counter {
  background-color: var(--softGrey);
  border-radius: 50%;
  border: 1px solid #444444;
  padding: 2px;
  width: 20px;
  height: 20px;
  text-align: center;
}

.causes-counter-white {
  background-color: var(--softWhite);
  border-radius: 50%;
  border: 1px solid #444444;
  padding: 2px;
  width: 20px;
  height: 20px;
  text-align: center;
}

.reasons-counter {
  background-color: var(--purpleBHP);
  border-radius: 2px;
  padding: 5px;
  font-weight: 600;
  color: var(--softWhite);
  width: 24px;
  height: 20px;
  text-align: center;
}

.white-background {
  background-color: var(--softWhite);
}
.Ion-toggle {
  z-index: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover {
  box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0.5) inset !important;
  -webkit-box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 1) inset !important;
}

.QRCode-icon {
  &.MuiSvgIcon-root {
    fill: transparent !important;
  }
}

.ideas-icon {
  transform: rotate(180deg);
  svg {
    transform: rotate(180deg);
  }
}

.ideas {
  svg {
    transform: rotate(180deg);
  }
}
.transparent-background {
  background-color: transparent;
}

/** Mixin **/

@mixin text-ellipsis($width) {
  width: $width;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
