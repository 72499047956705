.App-bar {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .action-buttons {
    display: flex;
    .action-button {
      display: flex;
      width: 2.75em;
      height: 2.75em;
      align-items: center;
      justify-content: center;
      background-color: white;
      border-radius: 50%;
      margin-left: 0.75em;
      cursor: pointer;

      .notifications {
        color: #444444;
        opacity: 0.54;
      }
      .menu-navigation {
        color: #444444;
        opacity: 0.54;
      }
    }
  }
}
.menu-navigation-dropdown {
  position: absolute;
  top: 80px;
  right: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit);
  grid-gap: 16px;
  background-color: var(--softWhite);
  border-radius: 8px;
  padding: 16px;
  z-index: 1;
  box-shadow: 4px 4px 10px rgba(68, 68, 68, 0.25);
  .Icon-text {
    cursor: pointer;
  }
  &.hide {
    display: none;
  }
}
