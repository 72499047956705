.Modal {
  font-family: GraphikRegular;

  .popover-content {
    border-radius: 1em;
    border: 1px solid #e0e0e0;
  }
  .popover-viewport {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
  }

  .modal-title {
    grid-area: title;
    padding: 1em 2em;
    height: max-content;
  }

  .modal-content {
    grid-area: content;
    overflow: hidden;
    height: max-content;
  }

  .modal-footer {
    display: flex;
    justify-content: flex-end;
    grid-area: footer;
    padding: 1.375em 1.25em;
    height: max-content;
  }
}

@supports (-webkit-touch-callout: none) {
  .popover-content {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
}
