.Share-qr {
  .popover-content {
    width: 23em;
    .modal-footer {
      flex-direction: column;
      padding: 0;
      .Holos-button {
        width: 100%;
        button{
          .Icon-text{
            justify-content: center;
          }
        }
      }
    }
  }
}
.toast {
  .popover-content {
    width: fit-content;
    .modal-title {
      padding: 0 !important;
    }
  }
}
