.IconSelect {
  display: flex;
  align-items: center;
  border-radius: 8px;
  .css-2b097c-container {
    width: 100%;
    .css-yk16xz-control {
      border: none;
    }
  }
  .css-2b097c-container:focus {
    outline: none;
  }
  .css-g1d714-ValueContainer:focus {
    outline: none;
  }
  .css-1hb7zxy-IndicatorsContainer {
    outline: none;
  }
  .css-1pahdxg-control:hover {
    border: none;
  }
  .css-1pahdxg-control:hover {
    box-shadow: none;
  }
  &.reverse {
    flex-direction: row-reverse;
    align-items: flex-end;
    .css-1hb7zxy-IndicatorsContainer {
      visibility: hidden;
    }
  }
  &.purple-select {
    .css-1hb7zxy-IndicatorsContainer {
      svg{
        fill: white;
        opacity: 1;
      }
    }
  }
  .css-1uccc91-singleValue {
    color: unset;
  }
}

.IconSelect.invalid {
  .css-1wa3eu0-placeholder {
    color: var(--error);
  }
}
